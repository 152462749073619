html body {
	background-color: #222;
	color: #eee;
}

pre {
	color: hsl(120 100% 70% / 1) !important;
}

hr {
	border-top: 1px solid rgba(255,255,255,.3) !important;
}

.container {
	margin-top: 24px;
}

.list-group-item {
	background: #333 !important;
	border-color: #555 !important;
}

body a {
	color: hsl(211deg 100% 70%);
}